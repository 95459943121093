import React, { useState } from "react";
import "@fontsource/source-sans-pro";
import "@fontsource/source-sans-pro/400.css";
import "@fontsource/source-sans-pro/400-italic.css";
import ScrollToTop from "./ScrollToTop";
import Navbar from "./Components/Header/Navbar";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Program from "./Pages/Program/Program";
import programs from "./Components/Programs/ProgramsData";
import CategoryPage from "./Pages/Program/CategoryPage";
import SubCategory from "./Pages/Program/SubCategory";
import { Course } from "./Pages/Program/Course";
import Internship from "./Pages/Program/Internship";
import Footer from "./Components/Footer/Footer";
import AboutUs from "./Pages/About Us/AboutUs";
import DegreePrograms from "./Pages/Degree Programs/DegreePrograms";
import Career from "./Pages/Career/Career";
import Enterprise from "./Pages/Enterprise/Enterprise";
import BScDatascienceAndCybersecurity from "./Pages/Degree Programs/Pages/BScDatascienceAndCybersecurity";
import BTechAIAndRobotics from "./Pages/Degree Programs/Pages/BTechAIAndRobotics";
import FrontendDeveloper from "./Pages/Career/Pages/FrontendDeveloper";
import BackendDeveloper from "./Pages/Career/Pages/BackendDeveloper";
import QualityAssuranceEngineer from "./Pages/Career/Pages/QualityAssuranceEngineer";
import DevOpsEngineer from "./Pages/Career/Pages/DevOpsEngineer";
import MobileAppDeveloper from "./Pages/Career/Pages/MobileAppDeveloper";
import WhatWeDo from "./Pages/What We Do/WhatWeDo";
import BTechFullStackDevelopment from "./Pages/Degree Programs/Pages/BTechFullStackDevelopment";
// import BTechCSEinRoboticsSpecializationInAI from "./Pages/Degree Programs/Pages/BTechCSEinRoboticsSpecializationInAI";
import BTechCSEinCloudComputingTechnology from "./Pages/Degree Programs/Pages/BTechCSEinCloudComputingTechnology";
import BTechCSEinBigDataAnalytics from "./Pages/Degree Programs/Pages/BTechCSEinBigDataAnalytics";
import BTechCSEinARandVR from "./Pages/Degree Programs/Pages/BTechCSEinARandVR";
import BTechinElectronicsandTelecommunicationEngineering from "./Pages/Degree Programs/Pages/BTechinElectronicsandTelecommunicationEngineering";
import BTechinElectronicsandInstrumentationEngineering from "./Pages/Degree Programs/Pages/BTechinElectronicsandInstrumentationEngineering";
import BTechinMechatronicsSpecializationinAIandML from "./Pages/Degree Programs/Pages/BTechinMechatronicsSpecializationinAIandML";
import BScInCybersecurity from "./Pages/Degree Programs/Pages/BScInCybersecurity";
import BScInDataScience from "./Pages/Degree Programs/Pages/BScInDataScience";
import AssistantProfessorEngineering from "./Pages/Career/Pages/AssistantProfessorEngineering";
import ProfessorEngineering from "./Pages/Career/Pages/ProfessorEngineering";
import AssociateProfessorEngineering from "./Pages/Career/Pages/AssociateProfessorEngineering";
import EnquiryForm from "./Pages/Enquiry/EnquiryForm";

function App() {

  const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);

  return (
    <div>
      <Navbar setBackgroundBlur={setIsBackgroundBlurred} />
      <div id="background" className={`xl:pt-12 max-sm:pt-12 ${isBackgroundBlurred ? '' : ''}`}>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={<Home />}
          />
          <Route
            path="/education/certification-programs"
            element={<Program programs={programs} />}
          />
          <Route
            path="/education/certification-programs/:category"
            element={<CategoryPage />}
          />
          <Route
            path="/education/certification-programs/:category/:subCategory"
            element={<SubCategory />}
          />
          <Route
            path="/education/certification-programs/:category/:subCategory/course"
            element={<Course />}
          />
          <Route
            path="/education/certification-programs/:category/:subCategory/internship"
            element={<Internship />}
          />
          <Route
            path="/about-us"
            element={<AboutUs />}
          />
          <Route
            path="/education/degree-programs"
            element={<DegreePrograms />}
          />
          <Route
            path="/education/degree-programs/bsc-data-science-and-cyber-security"
            element={<BScDatascienceAndCybersecurity />}
          />
          <Route
            path="/education/degree-programs/btech-robotics-and-ai"
            element={<BTechAIAndRobotics />}
          />
          <Route
            path="/education/degree-programs/btech-cse-with-full-stack-development"
            element={<BTechFullStackDevelopment />}
          />
          {/* <Route
            path="/education/degree-programs/btech-CSE-in-Robotics-Specialization-in-AI"
            element={<BTechCSEinRoboticsSpecializationInAI />}
          /> */}
          <Route
            path="/education/degree-programs/btech-CSE-in-cloud-computing-technology"
            element={<BTechCSEinCloudComputingTechnology />}
          />
          <Route
            path="/education/degree-programs/btech-CSE-with-big-data-analytics"
            element={<BTechCSEinBigDataAnalytics />}
          />
          <Route
            path="/education/degree-programs/btech-CSE-in-AR-&-VR"
            element={<BTechCSEinARandVR />}
          />
          <Route
            path="/education/degree-programs/btech-in-electronics-&-telecommunication-engineering"
            element={<BTechinElectronicsandTelecommunicationEngineering />}
          />
          <Route
            path="/education/degree-programs/btech-in-electronics-and-instrumentation-engineering"
            element={<BTechinElectronicsandInstrumentationEngineering />}
          />
          <Route
            path="/education/degree-programs/btech-in-mechatronics-specialization-in-AI-&-ML"
            element={<BTechinMechatronicsSpecializationinAIandML />}
          />
          <Route
            path="/education/degree-programs/bsc-in-cybersecurity"
            element={<BScInCybersecurity />}
          />
          <Route
            path="/education/degree-programs/bsc-in-data-science"
            element={<BScInDataScience />}
          />
          <Route
            path="/career"
            element={<Career />}
          />
          <Route
            path="/career/frontend-developer"
            element={<FrontendDeveloper />}
          />
          <Route
            path="/career/backend-developer"
            element={<BackendDeveloper />}
          />
          <Route
            path="/career/devops-engineer"
            element={<DevOpsEngineer />}
          />
          <Route
            path="/career/quality-assurance-engineer"
            element={<QualityAssuranceEngineer />}
          />
          <Route
            path="/career/mobile-app-development"
            element={<MobileAppDeveloper />}
          />
          <Route
            path="/career/assistant-professor-engineering-science"
            element={<AssistantProfessorEngineering />}
          />
          <Route
            path="/career/professor-engineering-science"
            element={<ProfessorEngineering />}
          />
          <Route
            path="/career/associate-professor-engineering-science"
            element={<AssociateProfessorEngineering />}
          />
          <Route
            path="/enterprise"
            element={<Enterprise />}
          />
          <Route
            path="/what-we-do"
            element={<WhatWeDo />}
          />
          <Route
            path="/enquiry-form"
            element={<EnquiryForm />}
          />
          <Route
            path="*"
            element={<Navigate to="/" />}
          />
        </Routes>
      </div>
      <Footer />
      <div className="fixed top-1/3 right-0 z-50 rotate-90">
        <a
          href="/enquiry-form"
          rel="noopener noreferrer"
        >
          <p className="absolute bg-blue-800 hover:bg-blue-900 text-white text-lg text-center py-1 w-[230px] rounded-b-xl animate-bounce">
            Admissions Open 2025
          </p>
        </a>
      </div>
    </div>
  );
}

export default App;
