import React from 'react';
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';

function AssociateProfessorEngineering() {

    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Career/Cover/professor%20cover%20image.jpg"
                        alt="Associate Professor – Engineering/Science"
                    />
                </div>
                <div className="relative py-10">
                    <div className='pl-40 xl:pl-40 sm:pl-20 max-sm:pl-10 text-4xl max-sm:text-2xl font-semibold tracking-tighter py-10'>
                        <FadeInSectionDownToUp>
                            <h1>Associate Professor – Engineering/Science.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                We are looking for an experienced Associate Professor with a passion for teaching and research. The ideal candidate will hold a Ph.D. in a relevant field and possess at least 8 years of experience in teaching, research, or industry, with a minimum of 2 years post-Ph.D. experience. In addition, candidates must have a strong research portfolio, with at least 7 publications in SCI-indexed journals and a research score of 75 as per UGC criteria.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Key Responsibilities.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Deliver exceptional teaching in undergraduate and graduate courses, incorporating the latest research and industry insights.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Supervise graduate and doctoral students, guiding them in their academic research and thesis projects.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Lead and contribute to research projects, with an emphasis on biomedical research.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Publish research findings in reputable SCI journals and other recognized academic platforms.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Develop new research initiatives and collaborate with industry professionals on applied research projects.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Play a key role in curriculum development and the continuous improvement of academic programs.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Contribute to the university’s research profile by seeking external funding and maintaining collaborative partnerships.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Provide leadership and mentorship to junior faculty members.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Represent the department in university committees and community outreach initiatives, helping shape the academic and research strategy of the institution.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='flex justify-center items-center'>
                        <FadeInSectionDownToUp>
                            <button
                                className='px-5 py-1 bg-[#02066f] text-white font-semibold rounded-lg hover:scale-105 transition-transform duration-700 ease-in-out'
                            >
                                <a href='https://docs.google.com/forms/d/1AsFd-__Qb0SNfCo-SCvq2zvIN6PjHXjzJizlorhAu6o/viewform' target='blank'>
                                    Apply Now
                                </a>
                            </button>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AssociateProfessorEngineering;
