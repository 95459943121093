import React from "react";
import { Link } from "react-router-dom";
import programs from "../Programs/ProgramsData";

const Footer = () => {
  return (
    <footer className="bg-gray-200 p-6 text-start">
      <div className="mb-4 grid grid-cols-4 xl:grid-cols-4 max-sm:grid-cols-1 gap-5">
        {programs.map((program) => (
          <div key={program.category} className="my-2">
            <Link to={`/education/certification-programs/${program.category}`} className="text-blue-600 hover:underline">
              {program.category}
            </Link>
            {program.subCategories && program.subCategories.map((subCategory) => (
              <h1>
                <Link
                  key={subCategory.name} // Use the name as the key
                  to={`/education/certification-programs/${program.category}/${subCategory.name}`}
                  className="text-gray-600 hover:underline"
                >
                  {subCategory.name} {/* Render the name property */}
                </Link>
              </h1>
            ))}
          </div>
        ))}
      </div>
      <div className="text-gray-500 text-sm text-center">
        <p>&copy; {new Date().getFullYear()} MH Cognition. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
