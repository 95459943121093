import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Enterprise() {
    return (
        <div>
            <div className='px-64 xl:px-64 sm:px-20 max-sm:px-10 py-20 max-sm:py-10 text-center'>
                <FadeInSectionDownToUp>
                    <p className='font-semibold py-2 text-5xl max-sm:text-3xl'>Simplify, optimize, and succeed.</p>
                    <p className='font-semibold py-2 text-gray-400 text-3xl max-sm:text-2xl px-20 max-sm:px-0'>
                        Transform your business with our all-in-one platform. Streamline sales, marketing, recruitment, and operations with tailored solutions like CRM, LMS, and ERP.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-20 xl:py-20 sm:py-0 sm:pb-20 max-sm:py-10 px-20 max-sm:px-10'>
                <FadeInSectionDownToUp>
                    <div className='flex justify-end items-center py-5'>
                        <div className='flex max-sm:block justify-between items-center gap-10 w-5/6 max-sm:w-full'>
                            <div>
                                <img className='rounded-3xl' src='https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/sales.jpg' alt='Sales Solutions' />
                            </div>
                            <div className='max-sm:pt-5'>
                                <p className='text-2xl font-semibold'>Sales Solutions.</p>
                                <p className='text-xl max-sm:text-justify'>
                                    Empower your sales team with our dynamic CRM and customizable forms. Streamline lead capture, track customer interactions, and close deals faster. Stay organized, improve follow-ups, and enhance your customer relationships all in one place.
                                </p>
                            </div>
                        </div>
                    </div>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <div className='flex justify-start items-center py-5'>
                        <div className='flex max-sm:flex-wrap-reverse justify-between items-center gap-10 max-sm:gap-5 w-5/6 max-sm:w-full'>
                            <div className='text-end max-sm:text-start'>
                                <p className='text-2xl font-semibold'>Marketing Solutions.</p>
                                <p className='text-xl max-sm:text-justify'>
                                    Elevate your marketing game with our all-in-one platform. Manage social media, create and track campaigns, design high-converting landing pages, and host seamless webinars. Reach your audience, measure impact, and drive results with ease.
                                </p>
                            </div>
                            <div>
                                <img className='rounded-3xl' src='https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/marketing.jpg' alt='Marketing Solutions' />
                            </div>
                        </div>
                    </div>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <div className='flex justify-end items-center py-5'>
                        <div className='flex max-sm:block justify-between items-center gap-10 w-5/6 max-sm:w-full'>
                            <div>
                                <img className='rounded-3xl' src='https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/hr.jpg' alt='Human Resources Solutions' />
                            </div>
                            <div className='max-sm:pt-5'>
                                <p className='text-2xl font-semibold'>Human Resources Solutions.</p>
                                <p className='text-xl max-sm:text-justify'>
                                    Simplify your recruitment process with MH Cognition. From posting job openings to streamlining candidate selection, our platform helps you attract, evaluate, and hire top talent efficiently. Save time, improve your hiring process, and build stronger teams.
                                </p>
                            </div>
                        </div>
                    </div>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-96 xl:px-96 sm:px-40 max-sm:px-10'>
                <FadeInSectionDownToUp>
                    <p className='text-4xl max-sm:text-2xl'>
                        Empower Your Business with Our<br />
                        <span className='text-8xl max-sm:text-5xl'><span className='font-bold'>SAAS</span> Solutions</span>
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-96 xl:px-96 sm:px-40 max-sm:px-10 py-10'>
                <FadeInSectionDownToUp>
                    <p className='text-2xl max-sm:text-justify'>
                        Our suite of SAAS products is designed to help businesses optimize processes and boost efficiency. From education to enterprise resource planning, we offer customized solutions to meet your needs.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex justify-center items-center'>
                <div className='space-y-7'>
                    <FadeInSectionDownToUp>
                        {/* <a href='/'> */}
                        <button onClick={() => alert('Product is under Development')}>
                            <p className='text-center text-xl bg-black text-white px-10 py-3 rounded-3xl w-56'>
                                <span className=''>CRM</span>
                            </p>
                        </button>
                        {/* </a> */}
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        {/* <a href='/'> */}
                        <button onClick={() => alert('Product is under Development')}>
                            <p className='text-center text-xl bg-black text-white px-10 py-3 rounded-3xl w-56 scale-150'>
                                <span className=''>LMS</span>
                            </p>
                        </button>
                        {/* </a> */}
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        {/* <a href='/'> */}
                        <button onClick={() => alert('Product is under Development')}>
                            <p className='text-center text-xl bg-black text-white px-10 py-3 rounded-3xl w-56'>
                                <span className=''>ERP</span>
                            </p>
                            {/* </a> */}
                        </button>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='px-40 max-sm:px-10 py-20'>
                <FadeInSectionDownToUp>
                    <p className='text-center text-5xl'>
                        Why Choose <span className='font-semibold'>MH Cognition?</span>
                    </p>
                </FadeInSectionDownToUp>
                <div className='py-10 px-80 xl:px-80 sm:px-40 max-sm:px-0'>
                    <FadeInSectionDownToUp>
                        <p className='text-xl text-justify'>
                            At MH Cognition, we believe in simplifying complexity. Our solutions are designed to integrate seamlessly across Sales, Marketing, and HR, driving greater efficiency and measurable results. With a focus on user-friendly design and powerful functionality, we help your business grow, perform, and succeed.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
