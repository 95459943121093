import React from 'react'
// import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';

export default function Home() {

    // const [activeIndex, setActiveIndex] = useState(0);

    // const handleOnSlideChanged = (event) => {
    //     setActiveIndex(event.item);
    // };

    // const images = [

    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/MH%20Cyber/Home/cover.jpg",
    //         href: "/",
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/MH%20Cyber/Home/cover.jpg",
    //         href: "/",
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/MH%20Cyber/Home/cover.jpg",
    //         href: "/",
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/MH%20Cyber/Home/cover.jpg",
    //         href: "/",
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/MH%20Cyber/Home/cover.jpg",
    //         href: "/",
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/MH%20Cyber/Home/cover.jpg",
    //         href: "/",
    //     },

    // ];

    return (
        <div>
            {/* <div>
                <AliceCarousel
                    autoPlay
                    disableButtonsControls
                    disableDotsControls
                    autoPlayInterval={2000}
                    buttonsDisabled={false}
                    dotsDisabled={true}
                    infinite={true}
                    swipeable={true}
                    draggable={true}
                    responsive={{
                        0: { items: 1 },
                        568: { items: 1 },
                        1024: { items: 1 },
                    }}
                >
                    <div><img src="https://ik.imagekit.io/mhcockpit1/Vels%20Landing/Vels%20Cover%20Rework.png" alt="MH Cognition" preload="auto" /></div>
                </AliceCarousel>
                <div><img src="https://ik.imagekit.io/mhcockpit1/Vels%20Landing/Vels%20Cover%20Rework.png" alt="MH Cognition" preload="auto" /></div>
            </div> */}
            {/* <div>
                <img src="https://ik.imagekit.io/mhcockpit1/MH%20Cyber/Home/cover.jpg" alt="MH Cognition" preload="auto" />
            </div> */}
            <div className='bg-black py-40 max-sm:py-20'>
                <div>
                    <FadeInSectionDownToUp>
                        <h1 className='text-9xl xl:text-9xl sm:text-9xl max-sm:text-7xl font-bold text-white text-center'>
                            MH <span className='from-purple-600 to-pink-600 bg-gradient-to-b bg-clip-text text-transparent'>Cognition</span>
                        </h1>
                    </FadeInSectionDownToUp>
                </div>
                <div className='px-[400px] xl:px-[400px] sm:px-[200px] max-sm:px-10 max-sm:text-justify py-10'>
                    <FadeInSectionDownToUp>
                        <p className='py-5 text-white text-xl text-justify'>
                            MH Cognition is at the forefront of technological innovation, specializing in Artificial Intelligence (AI), Machine Learning (ML), and advanced computing practices. Our mission is to harness the power of cutting-edge technology to drive transformative change across various industries. With a robust research and development team, we strive to create solutions that not only meet current demands but also anticipate future challenges.
                        </p>
                    </FadeInSectionDownToUp>
                    {/* <FadeInSectionDownToUp>
                        <p className='py-5 text-white text-xl text-justify'>
                            As part of our commitment to education and workforce development, we have established two key subsidiaries: MH Cockpit and MH Intellect.
                        </p>
                    </FadeInSectionDownToUp> */}
                </div>
                {/* <div className='flex justify-center items-center gap-8'>
                    <div>
                        <a href='https://mhcockpit.com/' target='blank'>
                            <button className='bg-white text-black font-semibold tracking-tighter px-5 py-1.5 rounded-full'>
                                MH Cockpit
                            </button>
                        </a>
                    </div>
                    <div>
                        <a href='https://mhintellect.com/' target='blank'>
                            <button className='bg-white text-black font-semibold tracking-tighter px-5 py-1.5 rounded-full'>
                                MH Intellect
                            </button>
                        </a>
                    </div>
                </div> */}
                <div className='flex max-sm:block justify-center items-center gap-8 max-sm:gap-0 pt-20 max-sm:pt-10 max-sm:px-10'>
                    {/* <div className="rounded-[20px] overflow-hidden shadow-lg w-1/4 xl:w-1/4 sm:w-1/3 max-sm:w-1/2 h-full relative flex flex-col">
                        <a href='/academy'>
                            <img src='https://ik.imagekit.io/mhcockpit1/MHB/Home/Rocket%20Thumbnail.png' alt='MH Cognition' />
                            <div className="absolute bottom-20 xl:bottom-20 sm:bottom-14 max-sm:bottom-10 left-1/4 xl:left-1/4 sm:left-8 max-sm:left-5 right-1/4 max-sm:right-0 text-white font-semibold text-4xl max-sm:text-xl z-10">
                                <FadeInSectionDownToUp>
                                    <p>
                                        Education.
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                        </a>
                    </div>
                    <div className="rounded-[20px] overflow-hidden shadow-lg w-1/4 xl:w-1/4 sm:w-1/3 max-sm:w-1/2 h-full relative flex flex-col">
                        <a href='/'>
                            <img src='https://ik.imagekit.io/mhcockpit1/MHB/Home/Rocket%20Thumbnail.png' alt='MH Cognition' />
                            <div className="absolute bottom-20 xl:bottom-20 sm:bottom-14 max-sm:bottom-10 left-1/4 xl:left-1/4 sm:left-8 max-sm:left-5 right-1/4 max-sm:right-0 text-white font-semibold text-4xl max-sm:text-xl z-10">
                                <FadeInSectionDownToUp>
                                    <p>
                                        Technology.
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                        </a>
                    </div> */}
                    <div className="bg-transparent rounded-[20px] overflow-hidden shadow-lg m-2 w-full md:w-1/3 xl:w-1/4 relative flex flex-col">
                        <a href='/education/certification-programs'>
                            <video autoPlay muted loop className="w-full h-full object-cover">
                                <source src="https://ik.imagekit.io/mhcockpit1/MH%20Cyber/Videos/Education.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div className="absolute bottom-[49px] left-4 text-white z-10 p-4 rounded-md">
                                <FadeInSectionDownToUp>
                                    <p className='text-start text-4xl max-sm:text-3xl px-5 w-96'>
                                        <b>Education.</b>
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                        </a>
                        <div className=" max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-transparent before:to-[#653416] before:opacity-85"></div>
                    </div>
                    <div className="bg-transparent rounded-[20px] overflow-hidden shadow-lg m-2 w-full md:w-1/3 xl:w-1/4 relative flex flex-col">
                        <a href='/enterprise'>
                            <video autoPlay muted loop className="w-full h-full object-cover">
                                <source src="https://ik.imagekit.io/mhcockpit1/MH%20Cyber/Videos/Technology.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div className="absolute bottom-[49px] left-4 text-white z-10 p-4 rounded-md">
                                <FadeInSectionDownToUp>
                                    <p className='text-start text-4xl max-sm:text-3xl px-5 w-96'>
                                        <b>Enterprise.</b>
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                        </a>
                        <div className=" max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-transparent before:to-[#581f70] before:opacity-85"></div>
                    </div>
                </div>
                <div className='px-[400px] xl:px-[400px] sm:px-[200px] max-sm:px-10 py-10'>
                    <FadeInSectionDownToUp>
                        <p className='py-5 text-white text-center text-xl'>
                            As part of our commitment to education and workforce development, we have established two key subsidiaries: MH Cockpit and MH Intellect.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                {/* <div className='flex justify-center items-center'>
                    <FadeInSectionDownToUp>
                        <p className='text-7xl max-sm:text-4xl font-bold text-white text-end'>
                            Transform your <span className='from-purple-600 to-pink-600 bg-gradient-to-b bg-clip-text text-transparent'>Career</span><br />
                        </p>
                        <p className='text-2xl font-normal text-white text-end pt-1'>
                            With  <span className='from-purple-600 to-pink-600 bg-gradient-to-b bg-clip-text text-transparent'>advanced technologies.</span><br />
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className="relative overflow-hidden pt-20">
                    <AliceCarousel
                        autoPlay
                        disableButtonsControls
                        disableDotsControls
                        autoPlayInterval={2000}
                        buttonsDisabled={false}
                        dotsDisabled={true}
                        infinite={true}
                        swipeable={true}
                        draggable={true}
                        onSlideChanged={handleOnSlideChanged}
                        responsive={{
                            0: { items: 1 },
                            568: { items: 1 },
                            1024: { items: 3 },
                        }}
                    >
                        {images.map((src, index) => (
                            <div className="m-5 transition-transform duration-500 ease-linear" key={index}>
                                <a href={src.href}>
                                    <img
                                        src={src.src}
                                        alt="MH Cognition"
                                        preload="auto"
                                        className={`transform transition-transform duration-500 ease-linear rounded-3xl ${index === (activeIndex + 1) % images.length ? 'scale-110 max-sm:scale-100' : 'scale-90 max-sm:scale-100'}`}
                                    />
                                </a>
                            </div>
                        ))}
                    </AliceCarousel>
                </div>
                <div className='flex justify-center items-center pt-20'>
                    <div>
                        <button className='bg-[#0f58b7] text-white text-xl px-8 py-3 rounded-full'>
                            Enroll Now.
                        </button>
                    </div>
                </div>
                <div className='px-[600px] xl:px-[600px] sm:px-[350px] max-sm:px-10 py-5'>
                    <FadeInSectionDownToUp>
                        <p className='py-5 text-gray-500 text-center'>
                            As part of our commitment to education and workforce development, we have established two key subsidiaries
                        </p>
                    </FadeInSectionDownToUp>
                </div> */}
            </div>
            {/* <div className='flex justify-center items-center py-40 max-sm:py-20'>
                <FadeInSectionDownToUp>
                    <p className='font-bold text-start text-[300px] max-sm:text-9xl text-[#130d79]'>
                        Begin
                    </p>
                    <p className='font-bold text-start text-8xl max-sm:text-4xl text-[#9a9a9a] -mt-28 max-sm:-mt-3 pl-6 max-sm:pl-3'>
                        with us
                    </p>
                </FadeInSectionDownToUp>
            </div> */}
        </div>
    )
}
