import React from 'react';
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';

function ProfessorEngineering() {

    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Career/Cover/professor%20cover%20image.jpg"
                        alt="Professor – Engineering/Science"
                    />
                </div>
                <div className="relative py-10">
                    <div className='pl-40 xl:pl-40 sm:pl-20 max-sm:pl-10 text-4xl max-sm:text-2xl font-semibold tracking-tighter py-10'>
                        <FadeInSectionDownToUp>
                            <h1>Professor – Engineering/Science.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                We are seeking a distinguished scholar to join our faculty as a Professor. The ideal candidate will have a Ph.D. in a relevant discipline with a track record of high-quality research, including at least ten research publications in peer-reviewed journals and a research score of at least 120 as per UGC guidelines. With a minimum of 10 years of teaching and research experience at the university level, the candidate will be responsible for leading innovative research initiatives and advancing the academic standing of the department.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Key Responsibilities.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Conduct and lead pioneering research, contributing significantly to the knowledge in the field.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Supervise and mentor doctoral candidates, guiding them through their research and dissertations.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Lead the development of new courses and ensure the curriculum remains cutting-edge and industry-relevant.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Publish regularly in high-impact, peer-reviewed journals and contribute to academic conferences.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Mentor junior faculty and provide academic leadership within the department.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Seek and secure funding for advanced research initiatives.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Contribute to the enhancement of academic programs through curriculum development and accreditation processes.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Represent the department and university in external academic and research communities.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Actively engage in departmental and institutional committees to shape the future of academic programs and research.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='flex justify-center items-center'>
                        <FadeInSectionDownToUp>
                            <button
                                className='px-5 py-1 bg-[#02066f] text-white font-semibold rounded-lg hover:scale-105 transition-transform duration-700 ease-in-out'
                            >
                                <a href='https://docs.google.com/forms/d/1AsFd-__Qb0SNfCo-SCvq2zvIN6PjHXjzJizlorhAu6o/viewform' target='blank'>
                                    Apply Now
                                </a>
                            </button>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfessorEngineering;
