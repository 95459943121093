// import React from 'react';
// import { Link } from 'react-router-dom';
// import programs from '../../Components/Programs/ProgramsData';

// const Program = () => {
//   return (
//     <div>
//       <h1>Programs</h1>
//       {programs.map((program, index) => (
//         <div key={index}>
//           <h2>{program.category}</h2>
//           <div>
//             {program.subCategories.map((sub, subIndex) => (
//               <div key={subIndex}>
//                 <Link to={`/program/${program.category}/${sub.name}`}>
//                   {sub.name}
//                 </Link>
//               </div>
//             ))}
//           </div>
//           <Link to={`/program/${program.category}`}>View More</Link>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Program;

// import React, { useRef } from 'react';
// import AliceCarousel from 'react-alice-carousel';
// import 'react-alice-carousel/lib/alice-carousel.css';
// import { GrNext, GrPrevious } from 'react-icons/gr';
// import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';
// import programs from '../../Components/Programs/ProgramsData';
// // import { Link } from 'react-router-dom';

// // Define the button components
// const Button = ({ onClick, position, children }) => (
//   <button
//     className={`absolute top-1/2 transform -translate-y-1/2 bg-gray-700 bg-opacity-85 text-white p-2 rounded-full ${position === 'next' ? 'right-4' : 'left-4'}`}
//     onClick={onClick}
//   >
//     {children}
//   </button>
// );

// const Program = ({ items, title, viewmore, viewmoreHref }) => {
//   const carouselRef = useRef(null);

//   const goToNext = () => {
//     if (carouselRef.current) {
//       carouselRef.current.slideNext();
//     }
//   };

//   const goToPrev = () => {
//     if (carouselRef.current) {
//       carouselRef.current.slidePrev();
//     }
//   };

//   return (
//     <div className="relative">
//       {programs.map((program, index) => (
//         <div key={index}>
//           <FadeInSectionDownToUp>
//             <div className='flex justify-between items-center px-12'>
//               <div>
//                 <p className="font-semibold text-xl">{program.category}</p>
//               </div>
//               {/* <div>
//                         {routes.map((route, index) => (
//                             <a key={index} href={route.href} className="font-semibold text-md text-blue-600">{viewmore}</a>
//                         ))}
//                     </div> */}
//               <a href={`/program/${program.category}`} className="font-semibold text-md text-blue-600">View More ➔</a>
//             </div>
//           </FadeInSectionDownToUp>
//           <AliceCarousel
//             ref={carouselRef}
//             autoPlay
//             disableButtonsControls
//             disableDotsControls
//             autoPlayInterval={2000}
//             buttonsDisabled={true}
//             dotsDisabled={true}
//             infinite={true}
//             swipeable={true}
//             draggable={true}
//             responsive={{
//               0: { items: 1 },
//               568: { items: 2 },
//               1024: { items: 3 },
//             }}
//           >
//             {/* {items.map((item, index) => (
//               <a href={item.link} key={index} className='bg-opacity-25 shadow-lg shadow-gray-300 m-10 h-max flex flex-col items-center rounded-2xl hover:scale-105 transition-transform duration-1000 ease-in-out' rel="noopener noreferrer">
//                 <img src={item.src} alt={item.alt} className='w-full rounded-t-2xl' />
//                 <p className='px-5 py-10 font-semibold text-md'>{item.title}</p>
//               </a>
//             ))} */}
//             {program.subCategories.map((sub, subIndex) => (
//               <div key={subIndex}>
//                 {/* <Link to={`/program/${program.category}/${sub.name}`}>
//                   {sub.name}
//                 </Link> */}
//                 <a href={`/program/${program.category}/${sub.name}`} className='bg-opacity-25 shadow-lg shadow-gray-300 m-10 h-max flex flex-col items-center rounded-2xl hover:scale-105 transition-transform duration-1000 ease-in-out' rel="noopener noreferrer">
//                   <img src={sub.src} alt={sub.alt} className='w-full rounded-t-2xl' />
//                   <p className='px-5 py-10 font-semibold text-md'>{sub.name}</p>
//                 </a>
//               </div>
//             ))}
//           </AliceCarousel>
//           <Button onClick={goToNext} position="next"><GrNext /></Button>
//           <Button onClick={goToPrev} position="prev"><GrPrevious /></Button>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Program;


// import React from 'react';
// import { Link } from 'react-router-dom';

// const Program = ({ programs }) => {
//   return (
//     <div>
//       <h1>Programs</h1>
//       {programs.map((program, index) => (
//         <div key={index}>
//           <h2>{program.category}</h2>
//           <div>
//             {program.subCategories.map((sub, subIndex) => (
//               <div key={subIndex}>
//                 <Link to={`/program/${program.category}/${sub.name}`}>
//                   {sub.name}
//                 </Link>
//               </div>
//             ))}
//           </div>
//           <Link to={`/program/${program.category}`}>View More</Link>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Program;


import React from 'react';
import CarouselSection from '../../Components/Carousel Section/CarouselSection';

const Program = ({ programs }) => {
  return (
    <div className='pt-10'>
      {programs.map((program, index) => (
        <div key={index}>
          <CarouselSection programs={[program]} />
        </div>
      ))}
    </div>
  );
};

export default Program;
