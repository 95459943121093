import React from 'react';
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';

function AssistantProfessorEngineering() {

    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Career/Cover/professor%20cover%20image.jpg"
                        alt="Assistant Professor - Engineering/Science"
                    />
                </div>
                <div className="relative py-10">
                    <div className='pl-40 xl:pl-40 sm:pl-20 max-sm:pl-10 text-4xl max-sm:text-2xl font-semibold tracking-tighter py-10'>
                        <FadeInSectionDownToUp>
                            <h1>Assistant Professor - Engineering/Science.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                We are looking for an Assistant Professor with a passion for teaching and a commitment to academic excellence. The ideal candidate will have a Master's degree or Bachelor’s degree with First Class in the appropriate branch of Engineering or Science. You will be responsible for delivering high-quality instruction in undergraduate and graduate courses, developing and updating course materials and syllabi to stay aligned with current industry standards, and using innovative teaching methods, including technology-enhanced learning.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Key Responsibilities.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Deliver exceptional instruction in both undergraduate and graduate-level courses.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Create, update, and refine course materials, syllabi, and teaching strategies to reflect the latest academic and industry trends.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Provide mentorship and academic guidance to students, fostering their academic and professional growth.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Assess student performance through assignments, exams, and projects.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Engage in original research, contributing to academic journals, conferences, and peer-reviewed publications.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Seek external funding to support research activities.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Collaborate with faculty, staff, and students on interdisciplinary research projects.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Contribute to the continuous improvement of curriculum and academic programs.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Participate in various departmental and university-wide committees.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Organize academic events and outreach programs, promoting academic excellence and community engagement.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='flex justify-center items-center'>
                        <FadeInSectionDownToUp>
                            <button
                                className='px-5 py-1 bg-[#02066f] text-white font-semibold rounded-lg hover:scale-105 transition-transform duration-700 ease-in-out'
                            >
                                <a href='https://docs.google.com/forms/d/1AsFd-__Qb0SNfCo-SCvq2zvIN6PjHXjzJizlorhAu6o/viewform' target='blank'>
                                    Apply Now
                                </a>
                            </button>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AssistantProfessorEngineering;
