import React, { useRef } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { GrNext, GrPrevious } from 'react-icons/gr';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';
import { Link } from 'react-router-dom';

const Button = ({ onClick, position, children }) => (
  <button
    className={`absolute top-1/2 transform -translate-y-1/2 bg-gray-700 bg-opacity-85 text-white p-2 rounded-full ${position === 'next' ? 'right-4' : 'left-4'}`}
    onClick={onClick}
  >
    {children}
  </button>
);

const CarouselSection = ({ programs }) => {
  const carouselRef = useRef(null);

  const goToNext = () => {
    if (carouselRef.current) {
      carouselRef.current.slideNext();
    }
  };

  const goToPrev = () => {
    if (carouselRef.current) {
      carouselRef.current.slidePrev();
    }
  };

  return (
    <div className="relative">
      {programs.map((program, index) => (
        <div key={index}>
          <FadeInSectionDownToUp>
            <div className='px-10 max-sm:px-2'>
              <div className='flex justify-between items-center pb-2'>
                <div>
                  <h1 className="font-semibold text-3xl max-sm:text-2xl">{program.category}.</h1>
                </div>
                <Link to={`/education/certification-programs/${program.category}`} className="font-semibold text-md text-blue-600">View More ➔</Link>
              </div>
              <hr />
            </div>
          </FadeInSectionDownToUp>
          <AliceCarousel
            ref={carouselRef}
            autoPlay
            disableButtonsControls
            disableDotsControls
            autoPlayInterval={2000}
            buttonsDisabled={true}
            dotsDisabled={true}
            infinite={true}
            swipeable={true}
            draggable={true}
            responsive={{
              0: { items: 1 },
              568: { items: 2 },
              1024: { items: 3 },
            }}
          >
            {program.subCategories.map((sub, subIndex) => (
              <div key={subIndex}>
                <Link to={`/education/certification-programs/${program.category}/${sub.name}`} className='bg-opacity-25 shadow-lg shadow-gray-300 m-10 max-sm:m-5 h-max flex flex-col items-center rounded-2xl hover:scale-105 transition-transform duration-1000 ease-in-out overflow-hidden group' rel="noopener noreferrer">
                  <img src={sub.src} alt={sub.name} className='w-full rounded-t-2xl group-hover:scale-110 transition-transform duration-1000 ease-in-out' loading='lazy' />
                  <p className='px-5 py-10 font-semibold text-md'>{sub.name}</p>
                </Link>
              </div>
            ))}
          </AliceCarousel>
          <Button onClick={goToNext} position="next"><GrNext /></Button>
          <Button onClick={goToPrev} position="prev"><GrPrevious /></Button>
        </div>
      ))}
    </div>
  );
};

export default CarouselSection;
