import React from 'react';
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';

function BackendDeveloper() {

    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Career/Cover/backend%20developer.jpg"
                        alt="Backend Developer"
                    />
                </div>
                <div className="relative py-10">
                    <div className='pl-40 xl:pl-40 sm:pl-20 max-sm:pl-10 text-4xl max-sm:text-2xl font-semibold tracking-tighter py-10'>
                        <FadeInSectionDownToUp>
                            <h1>Backend Developer.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                We are looking for a highly motivated and enthusiastic Backend Developer to join our dynamic team. Whether you're a fresher passionate about backend technologies or have 1-2 years of experience in Python and Django, we welcome your talent and drive to grow with us. In this role, you will contribute to the design, development, and maintenance of scalable, high-performance backend systems.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Responsibilities.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Develop, test, and maintain efficient, reusable, and reliable backend code using Python and Django.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Collaborate with front-end developers and other team members to integrate user-facing elements with server-side logic.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Design and implement RESTful APIs for seamless communication between frontend and backend systems.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Optimize applications for performance and scalability.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Work with databases such as PostgreSQL, MySQL, or MongoDB to manage and store application data.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Debug and resolve application issues and performance bottlenecks.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Write clear, maintainable documentation for code and processes.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Stay updated with emerging backend technologies and best practices.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Job Requirments.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        <b>For Freshers:</b> A strong passion for backend development and a willingness to learn. Demonstrated knowledge of Python and Django through projects, internships, or coursework is a plus.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        <b>For Experienced Candidates:</b> 1-2 years of professional experience in backend development using Python and Django.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Basic understanding of RESTful API design principles.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Familiarity with relational databases (PostgreSQL, MySQL) and non-relational databases (MongoDB) is preferred.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Knowledge of version control systems, such as Git.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Familiarity with cloud platforms (e.g., AWS, Azure, or Google Cloud) is a bonus.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Strong problem-solving skills and a collaborative mindset.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='flex justify-center items-center'>
                        <FadeInSectionDownToUp>
                            <button
                                className='px-5 py-1 bg-[#02066f] text-white font-semibold rounded-lg hover:scale-105 transition-transform duration-700 ease-in-out'
                            >
                                <a href='https://docs.google.com/forms/d/1AsFd-__Qb0SNfCo-SCvq2zvIN6PjHXjzJizlorhAu6o/viewform' target='blank'>
                                    Apply Now
                                </a>
                            </button>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BackendDeveloper;
