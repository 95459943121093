import React from 'react';
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';

function QualityAssuranceEngineer() {

    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Career/Cover/Quality%20assurance%20engineer.jpg"
                        alt="Quality Assurance Engineer"
                    />
                </div>
                <div className="relative py-10">
                    <div className='pl-40 xl:pl-40 sm:pl-20 max-sm:pl-10 text-4xl max-sm:text-2xl font-semibold tracking-tighter py-10'>
                        <FadeInSectionDownToUp>
                            <h1>Quality Assurance Engineer.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                We are seeking a detail-oriented Quality Assurance (QA) Engineer to join our team. The ideal candidate will have experience in testing applications built using Django and Python for the backend and React for the frontend. This role involves ensuring the delivery of high-quality, bug-free applications by designing and executing comprehensive test strategies and collaborating closely with the development team.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Responsibilities.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Design, develop, and execute test plans and test cases for applications built using Django (Python) and React.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Perform manual and automated testing of frontend and backend systems to ensure functionality, performance, and reliability.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Validate API endpoints and workflows for accuracy and data consistency.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Collaborate with developers to reproduce, diagnose, and resolve software defects.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Conduct regression testing to verify fixes and updates do not introduce new issues.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Work with test automation tools to streamline testing processes and maintain reusable test scripts.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Document and communicate test results, issues, and recommendations clearly and effectively.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Contribute to the improvement of the software testing lifecycle by identifying process inefficiencies.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Job Requirments.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        1-2 years of experience in software testing or QA.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Strong understanding of backend testing for Django (Python) applications.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Familiarity with frontend testing for React-based applications.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Experience with API testing tools like Postman, Swagger, or Rest Assured.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Proficiency in manual testing with a keen eye for UI/UX and functional details.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Familiarity with automated testing frameworks (e.g., Selenium, Cypress, or Pytest).
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Understanding of version control systems like Git.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Ability to write clear and reproducible bug reports.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <li className='text-xl ml-5 list-disc'>
                                        Strong communication skills and a collaborative mindset.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='flex justify-center items-center'>
                        <FadeInSectionDownToUp>
                            <button
                                className='px-5 py-1 bg-[#02066f] text-white font-semibold rounded-lg hover:scale-105 transition-transform duration-700 ease-in-out'
                            >
                                <a href='https://docs.google.com/forms/d/1AsFd-__Qb0SNfCo-SCvq2zvIN6PjHXjzJizlorhAu6o/viewform' target='blank'>
                                    Apply Now
                                </a>
                            </button>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QualityAssuranceEngineer;
