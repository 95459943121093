// src/Pages/Program/CategoryPage.js
import React from 'react';
import { useParams } from 'react-router-dom';
import programs from '../../Components/Programs/ProgramsData'; // Import your programs data
import { IoMdArrowBack } from "react-icons/io";

const CategoryPage = () => {
  const { category } = useParams(); // Get the category from the URL
  const selectedProgram = programs.find(program => program.category === category); // Find the corresponding program

  if (!selectedProgram) {
    return <div>Category not found.</div>; // Handle if the category does not exist
  }

  return (
    <div className="p-4">
      <div className='flex justify-start items-center gap-3 py-7'>
        <a href='/education/certification-programs'>
          <IoMdArrowBack size={30} />
        </a>
        <h1 className="text-3xl font-bold">{selectedProgram.category}</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {selectedProgram.subCategories.map((sub, index) => (
          <div key={index} className="bg-opacity-25 shadow-lg shadow-gray-300 m-2 rounded-2xl hover:scale-105 transition-transform duration-1000 ease-in-out overflow-hidden group">
            <a href={`/education/certification-programs/${selectedProgram.category}/${sub.name}`} className="flex flex-col items-center group-hover:scale-110 transition-transform duration-1000 ease-in-out">
              <img src={sub.src} alt={sub.name} className="w-full rounded-t-2xl" />
              <p className="px-5 py-8 font-semibold text-md">{sub.name}</p>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryPage;
