import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function AboutUs() {
    return (
        <div className=' backdrop-blur-3xl py-20 max-sm:py-0 bg-black text-white overflow-hidden'>
            <div className='bg-gray-400 max-sm:bg-transparent p-10 max-sm:p-0 max-sm:py-10 max-sm:px-5 bg-opacity-30 py-20 mx-[400px] xl:mx-[400px] sm:mx-[200px] max-sm:mx-0 rounded-3xl max-sm:rounded-none text-justify relativ'>
                <div className='bg-gradient-to-br from-[#AD49E1] via-[#6c159b] to-[#7A1CAC] w-[500px] max-sm:w-full h-[500px] rounded-[100%] absolute z-0 top-[30%] left-[50%] translate-x-[-110%] translate-y-[-110%] blur-[200px] overflow-auto'></div>
                {/* <div className='bg-gradient-to-br from-[#AD49E1] via-[#6c159b] to-[#7A1CAC] w-[500px] max-sm:w-full h-[500px] rounded-[100%] absolute z-0 top-[30%] left-[50%] translate-x-[10%] translate-y-[10%] blur-[200px] overflow-auto'></div> */}
                <div className='bg-gradient-to-br from-[#AD49E1] via-[#813dff] to-[#7A1CAC] w-[500px] max-sm:w-full h-[500px] rounded-[100%] absolute z-0 top-[30%] left-[50%] translate-x-[10%] translate-y-[10%] blur-[200px] overflow-auto'></div>
                {/* <div class="gradient-bg w-[500px] max-sm:w-full h-[500px] rounded-[100%] absolute z-0 top-[30%] left-[50%] translate-x-[-110%] translate-y-[-110%] blur-[200px] overflow-auto"></div> */}
                <div className='space-y-10 pb-5 '>
                    <div>
                        <FadeInSectionDownToUp>
                            <h1 className='text-center text-6xl max-sm:text-5xl tracking-tight font-semibold'>
                                Who We Are.
                            </h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className=''>
                        <FadeInSectionDownToUp>
                            <p className='text-xl'>
                                {/* MH Cognition is an IT training provider focused on equipping college students with the technical skills and real-world experience needed to thrive in today’s competitive job market. We bridge the gap between academic learning and industry demands, ensuring students are prepared for real-world challenges. Additionally, we offer innovative Software as a Service (SAAS) solutions to help businesses streamline operations and drive growth. */}
                                At MH Cognition, we are committed to transforming how organizations leverage technology through innovative software solutions. Specializing in tools for education, such as Learning Management Systems (LMS), Enterprise Resource Planning (ERP), Customer Relationship Management (CRM), and software training, we simplify processes, enhance user experiences, and support growth for businesses and educational institutions. We also provide IT training to equip college students with the technical skills and real-world experience essential for success in today’s competitive job market.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    {/* <div className=''>
                        <FadeInSectionDownToUp>
                            <p className='text-xl'>
                                In addition to our educational offerings, we provide cutting-edge business solutions through our innovative Software-as-a-Service (SAAS) applications. These solutions empower businesses to streamline operations, boost efficiency, and unlock new growth opportunities. By blending education with technology, we are shaping the next generation of IT professionals and supporting businesses in their digital transformation journey.
                            </p>
                        </FadeInSectionDownToUp>
                    </div> */}
                </div>
                <div className='space-y-10 py-5'>
                    <div>
                        <FadeInSectionDownToUp>
                            <h1 className='text-center text-6xl max-sm:text-5xl tracking-tight font-semibold'>
                                Vision & Mission.
                            </h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className=''>
                        <FadeInSectionDownToUp>
                            <p className='text-xl'>
                                {/* Our mission is to bridge the IT skills gap by empowering students with the knowledge and hands-on experience they need to succeed. We provide industry-leading training and internship opportunities that build technical expertise and real-world confidence. At the same time, we help businesses optimize their operations with our advanced SAAS solutions, driving efficiency and innovation. We aim to be the go-to destination for both student career development and business transformation, creating value and fostering success for all. */}
                                At MH Cognition, our vision is to be a leading innovator in empowering organizations and educational institutions through advanced software solutions that drive growth, efficiency, and success. Our mission is to simplify complex processes and enhance user experiences by creating powerful, user-centric tools for education and business. We are dedicated to equipping students with the technical skills and real-world knowledge needed to thrive in today’s competitive job market, while helping organizations unlock their full potential through transformative technology and comprehensive training.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
                {/* <div className='space-y-10 pt-5'>
                    <div>
                        <FadeInSectionDownToUp>
                            <h1 className='text-center text-7xl tracking-tight font-semibold'>
                                Our Mission.
                            </h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className=''>
                        <FadeInSectionDownToUp>
                            <p className='text-xl'>
                                At MH Cognition, our mission is to bridge the skills gap in the IT industry by empowering students with the knowledge, expertise, and hands-on experience they need to succeed. We are dedicated to providing college students with industry-leading training that not only enhances their technical skills but also builds confidence and practical knowledge. Our aim is to make learning relevant, impactful, and engaging.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className=''>
                        <FadeInSectionDownToUp>
                            <p className='text-xl'>
                                Simultaneously, we focus on offering innovative business solutions through our SAAS applications, helping organizations optimize their operations and achieve business goals efficiently. We are committed to creating value for both students and businesses by offering the tools, resources, and support needed to excel in a fast-paced digital world.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
