import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function WhatWeDo() {
    return (
        <div className=' backdrop-blur-3xl py-20 max-sm:py-0 bg-black text-white overflow-hidden'>
            <div className='bg-gray-400 max-sm:bg-transparent p-10 max-sm:p-0 max-sm:py-10 max-sm:px-5 bg-opacity-30 py-20 mx-[400px] xl:mx-[400px] sm:mx-[200px] max-sm:mx-0 rounded-3xl max-sm:rounded-none text-justify relativ'>
                <div className='bg-gradient-to-br from-[#AD49E1] via-[#6c159b] to-[#7A1CAC] w-[500px] max-sm:w-full h-[500px] rounded-[100%] absolute z-0 top-[30%] left-[50%] translate-x-[-110%] translate-y-[-110%] blur-[200px] overflow-auto'></div>
                {/* <div className='bg-gradient-to-br from-[#AD49E1] via-[#6c159b] to-[#7A1CAC] w-[500px] max-sm:w-full h-[500px] rounded-[100%] absolute z-0 top-[30%] left-[50%] translate-x-[10%] translate-y-[10%] blur-[200px] overflow-auto'></div> */}
                <div className='bg-gradient-to-br from-[#AD49E1] via-[#813dff] to-[#7A1CAC] w-[500px] max-sm:w-full h-[500px] rounded-[100%] absolute z-0 top-[30%] left-[50%] translate-x-[10%] translate-y-[10%] blur-[200px] overflow-auto'></div>
                <div className='space-y-10 pb-5 '>
                    <div>
                        <FadeInSectionDownToUp>
                            <h1 className='text-center text-6xl max-sm:text-5xl tracking-tight font-semibold'>
                                What We Do.
                            </h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className=''>
                        <FadeInSectionDownToUp>
                            <p className='text-xl'>
                                {/* At the forefront of innovation, we bridge the gap between education, invention, and research. Our mission is to empower the next generation of tech leaders, creators, and innovators with advanced training in cutting-edge technologies. We offer programs in Artificial Intelligence, Cloud Computing, Web Development, Cyber Security, Robotics, Data Science, Mobile Development, and Game Development, along with degree programs to ensure comprehensive learning. */}
                                At MH Cognition, we create innovative software solutions designed to optimize operations and improve user experiences for both businesses and educational institutions. Our expertise includes the development and implementation of Learning Management Systems (LMS), Enterprise Resource Planning (ERP) systems, and Customer Relationship Management (CRM) tools, all tailored to meet the specific needs of our clients. In addition, we offer specialized IT training programs that empower college students with the technical skills and hands-on experience necessary to excel in today’s competitive job market. Through our solutions and training, we aim to simplify processes, foster growth, and support the success of organizations and individuals alike.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    {/* <div className=''>
                        <FadeInSectionDownToUp>
                            <p className='text-xl'>
                                In addition, we provide SAAS applications like LMS, CRM, and ERP, designed to streamline business processes. Our hands-on, industry-aligned curriculum equips you with real-world experience, fostering creativity, critical thinking, and groundbreaking ideas to make an impact in a rapidly evolving digital world.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className=''>
                        <FadeInSectionDownToUp>
                            <p className='text-xl text-center font-semibold'>
                                Join us in shaping the future where learning, invention, and research converge to create endless possibilities.
                            </p>
                        </FadeInSectionDownToUp>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
