import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { IoMdClose } from 'react-icons/io';

const ApplyNowModal = ({ isOpen, onClose }) => {

  const [isVisible, setIsVisible] = useState(isOpen);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [currentPageUrl, setCurrentPageUrl] = useState(window.location.href);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 300); // Match duration with CSS transition
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isVisible) return null;

  const submit = async () => {
    const requiredFields = [name, email, mobileNumber];
    const hasEmptyFields = requiredFields.some(field => field === '');

    if (hasEmptyFields) {
      alert('Please fill in all required fields.');
      return;
    }

    try {
      const newData = {
        Name: name,
        Email: email,
        Mobile_Number: mobileNumber,
        Url: currentPageUrl
      };
      await axios.post('https://sheetdb.io/api/v1/s4s6lvqu09yko', newData);
      setName('');
      setEmail('');
      setMobileNumber('');
      setCurrentPageUrl('');
      console.log(newData)
    } catch (error) {
      console.error('Error in Submission', error);
      alert('Error in Submission');
    }
  };

  return (
    <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
      <div className={`bg-white p-6 rounded-lg shadow-lg w-96 relative transform transition-transform duration-300 ${isOpen ? 'scale-100' : 'scale-90'}`}>
        {/* Close Icon */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
        >
          <IoMdClose className="h-6 w-6" />
        </button>
        <h2 className="text-xl text-center font-semibold mb-4">Apply Now</h2>
        <input
          value={currentPageUrl}
          // onChange={(e) => setCurrentPageUrl(e.target.value)}
          type="hidden"
          name="currentPageUrl"
        />
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Name:</label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            required
            className="border rounded w-full px-3 py-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Email:</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            required
            className="border rounded w-full px-3 py-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Phone Number:</label>
          <input
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            type="number"
            required
            className="border rounded w-full px-3 py-2"
          />
        </div>
        <div className="flex justify-center">
          <button
            onClick={submit}
            type="submit"
            className="bg-blue-500 text-white rounded px-4 py-2"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApplyNowModal;

