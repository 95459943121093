import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Career() {

    const openings = [

        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Career/Card/FRONT%20END.jpg",
            title: "Frontend Developer",
            experience: "1-2 Years",
            location: "On Site",
            type: "Full Time",
            link: "/career/frontend-developer",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Career/Card/BAck%20END.jpg",
            title: "Backend Developer",
            experience: "1-2 Years",
            location: "On Site",
            type: "Full Time",
            link: "/career/backend-developer",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Career/Card/devops.jpg",
            title: "DevOps Engineer",
            experience: "1-2 Years",
            location: "On Site",
            type: "Full Time",
            link: "/career/devops-engineer",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Career/Card/Quality%20Assurance%20Engineer.jpg",
            title: "Quality Assurance Engineer",
            experience: "1-2 Years",
            location: "On Site",
            type: "Full Time",
            link: "/career/quality-assurance-engineer",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Career/Card/mobile%20app%20development.jpg",
            title: "Mobile App Developer",
            experience: "1-2 Years",
            location: "On Site",
            type: "Full Time",
            link: "/career/mobile-app-development",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Career/Card/professor.jpg",
            title: "Assistant Professor",
            experience: "0-5 Years",
            location: "On Site",
            type: "Full Time",
            link: "/career/assistant-professor-engineering-science",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Career/Card/professor.jpg",
            title: "Professor",
            experience: "Minimum 10 Years",
            location: "On Site",
            type: "Full Time",
            link: "/career/professor-engineering-science",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Career/Card/professor.jpg",
            title: "Associate Professor",
            experience: "Minimum 8 Years",
            location: "On Site",
            type: "Full Time",
            link: "/career/associate-professor-engineering-science",
        },

    ]

    return (
        <div>
            <div className='px-64 xl:px-64 sm:px-20 max-sm:px-10 py-20 max-sm:py-10 text-center'>
                <FadeInSectionDownToUp>
                    <p className='font-semibold py-2 text-6xl max-sm:text-3xl'>Transform Future with Us</p>
                    <p className='font-semibold py-2 text-gray-400 text-3xl max-sm:text-2xl px-20 max-sm:px-0'>
                        Looking for a place where you can make a real difference? At MH Cognition, we combine creativity, technology, and passion to transform industries. Join us, and let’s shape the future together.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex justify-center items-center pb-20 px-80 xl:px-80 sm:px-20 max-sm:px-5'>
                <div className='flex flex-col gap-10'>
                    {openings.map((card, index) => (
                        <FadeInSectionDownToUp>
                            <div key={index} className='rounded-3xl shadow-xl'>
                                <div className='grid grid-cols-2 max-sm:grid-cols-1 gap-10 max-sm:gap-2 items-center'>
                                    <div>
                                        <img className='rounded-l-3xl max-sm:rounded-bl-none max-sm:rounded-t-3xl' src={card.src} alt={card.title} />
                                    </div>
                                    <div className='max-sm:text-center max-sm:py-5'>
                                        <p className='pr-10 max-sm:pr-0 font-semibold text-3xl py-1'>{card.title}</p>
                                        <p className='text-xl py-1 text-gray-500'><span className='font-semibold text-black'>Experience: </span>{card.experience}</p>
                                        <p className='text-xl py-1 text-gray-500'><span className='font-semibold text-black'>Location: </span>{card.location}</p>
                                        <p className='text-xl py-1 text-gray-500'><span className='font-semibold text-black'>Type: </span>{card.type}</p>
                                        <div className='py-3'>
                                            <a href={card.link} className='text-xl py-1 bg-[#02066f] px-5 text-white rounded-lg'>Apply Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FadeInSectionDownToUp>
                    ))}
                </div>
            </div>
        </div>
    )
}
