import React, { useState } from "react";
import axios from "axios";
import { CountryCode } from "../../Components/Enquiry/CountryCode";

const EnquiryForm = () => {
    // Form data state
    const [formData, setFormData] = useState({
        fullName: "",
        countryCode: "",
        mobileNumber: "",
        alternativeCountryCode: "",
        alternativeNumber: "",
        email: "",
        programName: "",
        location: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { fullName, countryCode, mobileNumber, alternativeCountryCode, alternativeNumber, email, programName, location } = formData;

        if (mobileNumber === alternativeNumber) {
            alert("Mobile number and alternative mobile number cannot be the same.");
            return;
        }

        if (!fullName || !countryCode || !mobileNumber || !alternativeCountryCode || !alternativeNumber || !email || !programName || !location) {
            alert("Please fill out all fields.");
            return false;
        }
        try {
            const sendData = {
                Full_Name: formData.fullName,
                Country_Code: formData.countryCode,
                Mobile_Number: formData.mobileNumber,
                Alternative_Country_Code: formData.alternativeCountryCode,
                Alternative_Number: formData.alternativeNumber,
                Email: formData.email,
                Program_Name: formData.programName,
                Location: formData.location,
            };
            const response = await axios.post("https://sheetdb.io/api/v1/s13nqwks99jrk", sendData);
            console.log("Successful", response);
            setFormData({
                fullName: "",
                countryCode: "",
                mobileNumber: "",
                alternativeCountryCode: "",
                alternativeNumber: "",
                email: "",
                programName: "",
                location: "",
            });
            alert("Successfully submitted!");
        } catch (error) {
            console.error('Error Submission', error);
            alert("Error in Submission");
        }

        console.log("Form submitted:", formData);
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 max-sm:px-5">
            <div className="w-full max-w-lg bg-white p-8 max-sm:p-5 rounded-lg shadow-md">
                <h1 className="text-2xl font-bold text-center mb-4">Enquiry Form 2025</h1>
                {/* First Part of the Form */}
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
                            Enter Full Name:<span className='text-red-600'>*</span>
                        </label>
                        <input
                            type="text"
                            placeholder="Enter Full Name"
                            id="fullName"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            className="mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:outline-none rounded-md"
                            required
                        />
                    </div>
                    <div>
                        <label className='block'>Mobile Number:<span className='text-red-600'>*</span></label>
                        <div className='flex justify-center items-center gap-2'>
                            <select
                                className={`bg-transparent border border-gray-300 focus:border-blue-500 focus:outline-none text-center rounded w-1/4 py-2`}
                                name='countryCode'
                                value={formData.countryCode}
                                onChange={handleChange}
                            >
                                {CountryCode.map(code => (
                                    <option
                                        key={code.value}
                                        value={code.value}
                                        selected={code.selected}
                                        hidden={code.hidden}
                                        className='text-start'
                                    >
                                        {code.value || code.text}
                                    </option>
                                ))}
                            </select>
                            <input
                                className={`bg-transparent border border-gray-300 focus:border-blue-500 focus:outline-none rounded w-full py-2 px-3`}
                                type='number'
                                placeholder='Enter Mobile Number'
                                name='mobileNumber'
                                value={formData.mobileNumber}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div>
                        <label className='block'>Alternative Mobile Number:<span className='text-red-600'>*</span></label>
                        <div className='flex justify-center items-center gap-2'>
                            <select
                                className={`bg-transparent border border-gray-300 focus:border-blue-500 focus:outline-none text-center rounded w-1/4 py-2`}
                                name='alternativeCountryCode'
                                value={formData.alternativeCountryCode}
                                onChange={handleChange}
                            >
                                {CountryCode.map(code => (
                                    <option
                                        key={code.value}
                                        value={code.value}
                                        selected={code.selected}
                                        hidden={code.hidden}
                                        className='text-start'
                                    >
                                        {code.value || code.text}
                                    </option>
                                ))}
                            </select>
                            <input
                                className={`bg-transparent border border-gray-300 focus:border-blue-500 focus:outline-none rounded w-full py-2 px-3`}
                                type='number'
                                placeholder='Alternative Mobile Number'
                                name='alternativeNumber'
                                value={formData.alternativeNumber}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    {/* Email */}
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Mail ID:<span className='text-red-600'>*</span>
                        </label>
                        <input
                            type="email"
                            placeholder="Enter Mail ID"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:outline-none rounded-md"
                            required
                        />
                    </div>

                    {/* Program Name */}
                    <div>
                        <label htmlFor="programName" className="block text-sm font-medium text-gray-700">
                            Select Program:<span className='text-red-600'>*</span>
                        </label>
                        <select
                            id="programName"
                            name="programName"
                            value={formData.programName}
                            onChange={handleChange}
                            className="mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:outline-none rounded-md"
                            required
                        >
                            <option value="" disabled selected hidden>Select Program</option>
                            <option>B.Sc Data Science & Cybersecurity</option>
                            <option>B.Tech in Robotics and Artificial Intelligence</option>
                            <option>B.Tech - CSE with Full Stack Development</option>
                            {/* <option>B.Tech - CSE in Robotics specialization in AI</option> */}
                            <option>B.Tech - CSE in Cloud Computing Technology</option>
                            <option>B.Tech - CSE with Big Data Analytics</option>
                            <option>B.Tech - CSE in AR & VR</option>
                            <option>B.Tech in Electronics & Telecommunication Engineering</option>
                            <option>B.Tech in Electronics and Instrumentation Engineering</option>
                            <option>B.Tech in Mechatronics specialization in AI & ML</option>
                            <option>B.Sc in Cybersecurity</option>
                            <option>B.Sc in Data Science</option>
                        </select>
                    </div>

                    {/* Location */}
                    <div>
                        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                            Location:<span className='text-red-600'>*</span>
                        </label>
                        <textarea
                            type="text"
                            placeholder="Your Address"
                            id="location"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                            className="mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:outline-none rounded-md"
                        />
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="w-full bg-green-500 text-white py-2 rounded-md mt-4 hover:bg-green-600"
                    >
                        Submit Form
                    </button>
                </form>
            </div>
        </div>
    );
};

export default EnquiryForm;
