import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import programs from '../../Components/Programs/ProgramsData';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';
import ApplyNowModal from '../../Components/Apply Now/ApplyNowModal';

export default function Internship() {

    const { category, subCategory } = useParams();
    const program = programs.find(p => p.category === category);
    const sub = program.subCategories.find(s => s.name === subCategory);

    const [expandedFAQ, setExpandedFAQ] = useState(null);


    const toggleFAQAccordion = (index) => {
        setExpandedFAQ(expandedFAQ === index ? null : index);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <div>
            {sub.internships.map((intern, index) => (
                <div key={index}>
                    <div>
                        <img src={intern.cover} alt={intern.title} preload="auto" className='w-full' />
                    </div>
                    <div className='bg-black py-20 space-y-3'>
                        <FadeInSectionDownToUp>
                            <h1 className='text-[#fb5d08] text-9xl xl:text-9xl max-sm:text-7xl text-center font-semibold py-5'>Internship.</h1>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-center text-xl font-bold'>
                                {intern.introBold}
                            </p>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-center text-xl px-52 xl:px-52 max-sm:px-10'>
                                {intern.intro}
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex justify-center items-center'>
                        <div>
                            <FadeInSectionDownToUp>
                                <p className='text-[300px] font-bold text-[#fb5d08]'>3</p>
                            </FadeInSectionDownToUp>
                        </div>
                        <div>
                            <FadeInSectionDownToUp>
                                <p className='text-[60px] font-semibold'>
                                    <span className='text-[#fb5d08]'>Months</span><br />
                                    Duration
                                </p>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    <div className='pb-32'>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl text-center'>
                                <span className='text-[#fb5d08]'>Compensation </span> Varies based on experience and performance.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='bg-black px-40 xl:px-32 sm:px-20 max-sm:px-10 py-32'>
                        <FadeInSectionDownToUp>
                            <p className='text-9xl xl:text-9xl max-sm:text-5xl sm:text-7xl text-center text-white font-bold'>
                                What <span className='text-[#fb5d08]'>You'll Do.</span>
                            </p>
                        </FadeInSectionDownToUp>
                        <div className='flex justify-center items-center pt-20 px-24 xl:px-24 sm:px-0 max-sm:px-0'>
                            <div className='grid grid-cols-4 max-sm:grid-cols-1 gap-32 xl:gap-32 sm:gap-12 max-sm:gap-5'>
                                {intern.youllDo.map((point, index) => (
                                    <div key={index} className='flex flex-col justify-between h-full'>
                                        <FadeInSectionDownToUp>
                                            <p className='text-xl text-white'>
                                                {point}
                                            </p>
                                        </FadeInSectionDownToUp>
                                        <hr className='py-0.5 my-5 bg-[#fb5d08] border-[#fb5d08] w-full' />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='py-20'>
                        <FadeInSectionDownToUp>
                            <p className='text-center text-5xl tracking-tighter'>
                                Why Choose <span className='font-semibold text-[#fb5d08]'>MH Cyber?</span>
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex justify-center items-center px-40 max-sm:px-10 py-20'>
                        <div className='grid grid-cols-2 max-sm:grid-cols-1 gap-5 px-60 xl:px-60 sm:px-20 max-sm:px-0'>
                            {intern.whyChoose.map((item, index) => (
                                <div key={index} className={`p-10 bg-[#f2f2f2] rounded-3xl ${item.align}`}>
                                    <FadeInSectionDownToUp>
                                        <p className='text-xl font-semibold text-[#fb5d08]'>
                                            {item.title}
                                        </p>
                                        <p className='text-xl'>
                                            {item.description}
                                        </p>
                                    </FadeInSectionDownToUp>
                                </div>
                            ))}
                        </div>
                    </div>
                    <FadeInSectionDownToUp>
                        <div className='flex justify-center items-center py-20'>
                            <button onClick={openModal} className='bg-black text-white py-2 rounded-3xl mx-56 max-sm:mx-0 w-40'>Apply Now</button>
                        </div>
                    </FadeInSectionDownToUp>
                    <div className='py-10'>
                        <FadeInSectionDownToUp>
                            <p className='text-center text-8xl font-bold text-[#868686]'>FAQs.</p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="rounded-xl mx-72 xl:mx-72 sm:mx-40 my-20 max-sm:mx-5 space-y-5">
                        <div className="rounded-xl my-20 space-y-5">
                            {intern.faq.map((item, index) => (
                                <div key={index}>
                                    <div
                                        className={`p-5 cursor-pointer rounded-[30px] shadow-xl h-full w-full
                                        ${expandedFAQ === index ? 'bg-black text-white h-auto' : 'bg-black text-white'}`}
                                        onClick={() => toggleFAQAccordion(index)}
                                    >
                                        <h5 className="text-lg font-semibold">
                                            <FadeInSectionDownToUp>{item.question}</FadeInSectionDownToUp>
                                        </h5>
                                    </div>
                                    <div
                                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expandedFAQ === index ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                                            }`}
                                    >
                                        <div className="p-4 bg-white text-lg max-sm:text-sm px-10 max-sm:px-4">
                                            <p>{item.answer}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
            <ApplyNowModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
    )
}
