import React from 'react';
import { useParams, Link } from 'react-router-dom';
import programs from '../../Components/Programs/ProgramsData';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';

const SubCategory = () => {
    const { category, subCategory } = useParams();
    const program = programs.find(p => p.category === category);
    const sub = program.subCategories.find(s => s.name === subCategory);

    const progressionData = sub.progressionData || [];

    return (
        <div>
            <div>
                <img src={sub.coverImage} alt={sub.name} preload="auto" className='w-full' />
            </div>
            <div className='px-20 max-sm:px-5 py-40 max-sm:py-20'>
                <div className='w-full'>
                    <FadeInSectionDownToUp>
                        <h1 className='text-9xl xl:text-6xl sm:text-7xl max-sm:text-5xl text-center text-[#006c95] font-bold'>
                            {sub.name}.
                        </h1>
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        <p className='text-xl text-center px-40 xl:px-40 sm:px-20 max-sm:px-1 py-10'>
                            {sub.description}
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            {/* <div className='flex max-sm:block justify-center items-center xl:gap-0 sm:gap-10 px-20 max-sm:px-5 py-10'>
                <div className='w-full'>
                    <FadeInSectionDownToUp>
                        <h1 className='text-9xl max-sm:text-7xl text-[#006c95] font-bold'>
                            {sub.name}.
                        </h1>
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        <p className='text-xl pr-40 xl:pr-40 sm:pr-10 max-sm:pr-0 py-10'>
                            {sub.description}
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='w-full flex justify-center items-center'>
                    <img className='p-32 xl:p-32 sm:p-0 max-sm:p-0' src={sub.gridImage} alt={sub.name} preload="auto" />
                </div>
            </div> */}
            <div className='flex justify-center items-center'>
                <FadeInSectionDownToUp>
                    <p className='text-4xl'>
                        In a World of <br />
                        <span className='text-[#006c95] font-semibold'>Algorithms</span>, Be the <br />
                        <span className='text-6xl max-sm:text-5xl text-[#006c95] font-bold'>Architect.</span>
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-20 max-sm:py-10 bg-[#f2f2f2] mt-20'>
                <div className='px-32 xl:px-32 sm:px-20 max-sm:px-5 py-16'>
                    <FadeInSectionDownToUp>
                        <p className='text-5xl'>
                            Expertise <span className='font-semibold'>Progression.</span>
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='flex justify-center items-center px-32 xl:px-32 sm:px-20 max-sm:px-5'>
                    <div className='grid grid-cols-2 max-sm:grid-cols-1 gap-5 w-full'>
                        {progressionData.map((point, index) => (
                            <div key={index} className='bg-black px-5 py-3 rounded-full text-white text-center border'>
                                <FadeInSectionDownToUp>
                                    <p>{point}</p>
                                </FadeInSectionDownToUp>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='bg-white mx-20 max-sm:mx-5 px-20 max-sm:px-5 my-10 py-20 rounded-3xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-center text-4xl'>
                                <span className='font-semibold'>Tools</span> Used.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex flex-wrap justify-center items-center gap-8 py-5'>
                        {/* <div className='grid grid-cols-3'> */}
                            {sub.tools.map((tool, index) => (
                                <img key={index} className='xl:w-1/12 sm:w-20 max-sm:w-20' src={tool.image} alt={tool.name} />
                            ))}
                        {/* </div> */}
                    </div>
                </div>
                <FadeInSectionDownToUp>
                    <div className='grid grid-cols-2 gap-2 justify-center items-center mx-56 xl:mx-56 sm:mx-10 max-sm:mx-10 px-56 xl:px-56 sm:px-10 max-sm:px-10'>
                        <Link to={`/education/certification-programs/${category}/${subCategory}/course`} className='bg-black text-white text-center py-2 rounded-3xl mx-10 max-sm:mx-0'>Course</Link>
                        <Link to={`/education/certification-programs/${category}/${subCategory}/internship`} className='bg-black text-white text-center py-2 rounded-3xl mx-10 max-sm:mx-0'>Internship</Link>
                    </div>
                </FadeInSectionDownToUp>
            </div>
        </div>
    );
};

export default SubCategory;
